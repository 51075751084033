import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Facebook, Instagram } from '@mui/icons-material';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="/">
        Bóveda Celeste
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};


export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 2, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item>
            <Typography variant="caption">
              <Box component="a" href="/" sx={iconStyle}>
                <Facebook />
              </Box>
              <Box component="a" href="/" sx={iconStyle}>
                <Instagram />
              </Box>
              <Copyright />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
