import * as React from 'react';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorksOpen from './modules/views/ProductHowItWorksOpen';
import ProductCategoriesOpen from './modules/views/ProductCategoriesOpen';
import ProductHowItWorksGlobular from './modules/views/ProductHowItWorksGlobular';
import ProductCategoriesGlobular from './modules/views/ProductCategoriesGlobular';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      <ProductCategoriesOpen />
      <ProductHowItWorksOpen />
      <ProductCategoriesGlobular />
      <ProductHowItWorksGlobular />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
