import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
        onClick={() => window.open('mailto:contacto@proteco.com.mx')}
      >
        <Typography variant="h4" component="span">
          ¿Todavia tienes dudas? Contactanos aqui
        </Typography>
      </Button>
    </Container>
  );
}

export default ProductSmokingHero;
